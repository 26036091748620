import { Button } from "@mui/material"
import 'App.css'

export const Home = () => {

	return (
		<div>
			<h1 >testing</h1>
			<h2>Home page</h2>
			<p color='red' >Here will be something in future...</p>
			<Button className="Button">testi</Button>
		</div>
	)
}
